import React from "react"
import { string } from "prop-types"
import { useI18next } from "gatsby-plugin-react-i18next"

function ChangeLang({ page }) {
  const { language, languages, changeLanguage } = useI18next()

  return (
    <>
      {languages.map(lang => (
        <li
          key={lang}
          className={page === "homepage" ? "header--language" : undefined}
          style={{
            display: lang !== language ? "inline-block" : "none",
          }}
        >
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              changeLanguage(lang)
            }}
          >
            {lang.toUpperCase()}
          </a>
        </li>
      ))}
    </>
  )
}

ChangeLang.propTypes = {
  page: string,
}

export default ChangeLang
