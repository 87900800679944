import React, { Component } from "react"
import { any } from "prop-types"
import { Link, Trans } from "gatsby-plugin-react-i18next"
import { Phone, Mail, Facebook, Twitter, Linkedin, Instagram } from "react-feather"

import Img from "gatsby-image"

import "./style.scss"

import LogoBankBNI from "../../images/logo-bank-bni.png"
import LogoBankBRI from "../../images/logo-bank-bri.png"
import LogoBankMandiri from "../../images/logo-bank-mandiri.png"
import LogoTredo from "../../images/tredo-logo.png"
import LogoKJPP from "../../images/logo-kjpp.png"


class Footer extends Component {
  static propTypes = {
    siteLoog: any,
  }

  render() {
    const { siteLogo } = this.props

    return (
      <div className="footer-wrapper">
        <div className="footer-wrapper__first">
          <div className="footer-wrapper__about">
            <Img fluid={siteLogo} />
            <p>
              <Trans>About Short Description</Trans>
              <Link to="/about">
                <Trans>Baca Selengkapnya</Trans>
              </Link>
            </p>
            <div className="footer-wrapper__about-share">
              <a
                href="https://www.linkedin.com/company/pt-provalindo-nusa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin color="#1F3961" />
              </a>
              <a
                href="https://www.facebook.com/nusa.property.96"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook color="#1F3961" />
              </a>
              <a
                href="https://twitter.com/provalindonusa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter color="#1F3961" />
              </a>
              <a
                href="https://instagram.com/provalindonusa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram color="#1F3961" />
              </a>
            </div>
          </div>
          <div className="footer-wrapper__partner">
            <h3>
              <Trans>Rekanan Kami</Trans>
            </h3>
            <div className="footer-wrapper__partner-img">
              <a
                href="https://tredo.co.id"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LogoTredo} alt="Logo Tredo Media" />
              </a>
              <a
                href="https://fsr.co.id/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LogoKJPP} alt="Logo KJPP" />
              </a>
              <img src={LogoBankBNI} alt="Logo Bank BNI" />
              <img src={LogoBankBRI} alt="Logo Bank BRI" />
              <img src={LogoBankMandiri} alt="Logo Bank Mandiri" />
            </div>
          </div>
        </div>
        <div className="footer-wrapper__second">
          <h3>
            <Trans>Alamat</Trans>
          </h3>
          <div className="footer-wrapper__office">
            <div>
              <p>
                <b>
                  Jakarta (<Trans>Kantor Pusat</Trans>)
                </b>
                <br />
                Wijaya Grand Center, Blok F Nomor 36 B Jalan Wijaya II,
                Kebayoran Baru Jakarta Selatan 12160
              </p>
              <div className="has-icon">
                <Phone color="#B1B1B1" className="mr-8" /> +6221 7279 0338
              </div>
              <div className="has-icon">
                <Phone color="#B1B1B1" className="mr-8" /> +6221 7279 0341
              </div>
              <div className="has-icon">
                <Mail color="#B1B1B1" className="mr-8" />{" "}
                info@provalindonusa.com
              </div>
            </div>

            <div>
              <p>
                <b>Surabaya</b>
                <br />
                Jalan Bendul Merisi Selatan 4 Nomor 11 Surabaya, Jawa Timur
                60239
              </p>
            </div>
            <div>
              <p>
                <b>Medan</b>
                <br />
                Jalan Elang Nomor 8 C Lt. 2 dan 3, Sei Sikambing Medan 20122
              </p>
            </div>
            {/* <div>
              <p>
                <b>Pekanbaru</b>
                <br />
                Jalan Paus Permai II Villa Indah Paus D 24 Pekanbaru, Riau
              </p>
            </div> */}
          </div>
        </div>
        <div className="footer-wrapper__copyright">{`© ${new Date().getFullYear()}, Provalindo Nusa`}</div>
      </div>
    )
  }
}

export default Footer
