import React, { Component } from "react"
import { node, string } from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "../Header"
import Footer from "../Footer"
import Container from "../Container"

import "./style.scss"

class Layout extends Component {
  static propTypes = {
    children: node,
    page: string,
  }

  render() {
    const { children, page } = this.props

    return (
      <StaticQuery
        query={layoutQuery}
        render={data => (
          <div className="layout-wrapper">
            <Container>
              <Header
                siteTitle={data.site.siteMetadata.title}
                siteLogo={
                  page === "homepage"
                    ? data.logoImageWhite.childImageSharp.fluid
                    : data.logoImage.childImageSharp.fluid
                }
                page={page}
              />
            </Container>

            <main>{children}</main>

            <div className="layout-wrapper-separator"></div>
            <Container>
              <Footer siteLogo={data.logoImage.childImageSharp.fluid} />
            </Container>
          </div>
        )}
      />
    )
  }
}

export default Layout

const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    logoImage: file(relativePath: { eq: "images/Logo-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoImageWhite: file(relativePath: { eq: "images/Logo-02-white.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
