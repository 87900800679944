import React, { Component } from "react"
import { any, string } from "prop-types"
import { Menu, X } from "react-feather"
import { Link } from "gatsby-plugin-react-i18next"

import Img from "gatsby-image"
import ChangeLang from "../ChangeLang"

import "./style.scss"

class Header extends Component {
  static propTypes = {
    stiteTitle: string,
    siteLogo: any,
    page: string,
    i18n: any,
  }

  state = {
    isShowMenu: false,
  }

  handleShowMenu() {
    const { isShowMenu } = this.state
    this.setState({
      isShowMenu: !isShowMenu,
    })
  }

  render() {
    const { isShowMenu } = this.state
    const { siteTitle, siteLogo, page } = this.props

    const ListLink = props => (
      <li className={page === "homepage" ? "header--homepage" : undefined}>
        <Link to={props.to} activeClassName="is-active">
          {props.children}
        </Link>
      </li>
    )

    return (
      <nav className="header-wrapper">
        <Link to="/">
          <Img fluid={siteLogo} alt={`${siteTitle}'s Logo`} />
        </Link>
        <ul className="hide-on-mobile">
          <ListLink to="/services">SERVICES</ListLink>
          <ListLink to="/portfolio">PORTFOLIO</ListLink>
          <ListLink to="/insight">INSIGHT</ListLink>
          <ChangeLang page={page} />
        </ul>
        <div className="hide-on-desktop" onClick={() => this.handleShowMenu()}>
          {!isShowMenu ? (
            <Menu color={page === "homepage" ? "#ffffff" : "#1F3961"} />
          ) : (
            <X color={page === "homepage" ? "#ffffff" : "#1F3961"} />
          )}
        </div>
        {isShowMenu ? (
          <ul className="menu-on-mobile hide-on-desktop">
            <ListLink to="/services">SERVICES</ListLink>
            <ListLink to="/portfolio">PORTFOLIO</ListLink>
            <ListLink to="/insight">INSIGHT</ListLink>
            <ChangeLang page={page} />
          </ul>
        ) : null}
      </nav>
    )
  }
}

export default Header
